.ParentsBg {
  position: relative;
  background-image: url("..\/Photos\/parents.png");
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 40%;
  overflow: hidden;

}

.filter1 {
  position: absolute;
  padding-top: 50%;
  padding-bottom: 50%;
  background-color: rgb(0, 0, 0, 0.7);
  top: 50%;
  /* bottom: 0; */

  transform: translate(0%, -50%);
}

.anchorborder {
  border: solid;
  border-radius: 5px;
  border-color: #003a87;
  border-style: 5px;
  position: relative;
  padding: 8px 18px;



  color: #003a87;
  overflow: hidden;
}

.anchorborder p {
  font-weight: bolder;

}

.anchorborder:hover p {
  position: relative;
  z-index: 99;
  color: white;

}

.anchorborderhover {
  position: absolute;
  background-color: #003a87;
  color: white;
  /* color: white; */
  height: 100%;
  width: 100%;
  left: -100%;
  top: 0%;
  bottom: 0%;
  right: 0%;
  transition: all ease .6s;


}

.anchorborder:hover .anchorborderhover {
  left: 0%;
}

.cornerbox {
  /* display: block; */
  /* border:solid;
    border-color: #003a87;
    border-style: 5px; */
  /* From https://css.glass */
  background: rgba(24, 3, 141, 0.79);
  border-radius: 16px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  border: 1px solid rgba(24, 3, 141, 0.3);
  /* background-color: rgb(0,0,0,200);
    background-color:#003a87 ; */
  width: 150px;
  height: 150px;
  margin: 20px auto;
  transform: rotate(45deg);
  border-radius: 10px;
  transition: all ease 2s;
}

.cornerbox:hover {
  transform: rotateZ(405deg);
}

.backrotate {
  transform: rotate(-45deg);
}
































h1 {
  font-size: 40px;
  line-height: 0.8em;
}


button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.tabs {
  display: block;
  background-image: url("../Photos/bg_texture.png");
  background-repeat: none;
  background-size: cover;
  background-color: #003a87;
  border-radius: 12px;
  height: 600px;

  color: white;
  padding: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden;
}

.tabs [class^="tab"] label,
.tabs [class*=" tab"] label {
  color: #efedef;
  cursor: pointer;
  display: block;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em;
  padding: 2rem 0;
  text-align: center;
}

.tabs [class^="tab"] [type="radio"],
.tabs [class*=" tab"] [type="radio"] {
  border-bottom: 1px solid rgba(239, 237, 239, 0.5);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs [class^="tab"] [type="radio"]:hover,
.tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:hover,
.tabs [class*=" tab"] [type="radio"]:focus {
  border-bottom: 1px solid #fd264f;
}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class*=" tab"] [type="radio"]:checked {
  border-bottom: 2px solid #fd264f;
}

.tabs [class^="tab"] [type="radio"]:checked+div,
.tabs [class*=" tab"] [type="radio"]:checked+div {
  opacity: 1;
}

.tabs [class^="tab"] [type="radio"]+div,
.tabs [class*=" tab"] [type="radio"]+div {
  display: block;
  opacity: 0;
  padding: 2rem 0;
  width: 90%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tabs .tab-2 {
  width: 50%;
}

.tabs .tab-2 [type="radio"]+div {
  width: 200%;
  margin-left: 200%;
}

.tabs .tab-2 [type="radio"]:checked+div {
  margin-left: 0;
}

.tabs .tab-2:last-child [type="radio"]+div {
  margin-left: 100%;
}

.tabs .tab-2:last-child [type="radio"]:checked+div {
  margin-left: -100%;
}
















































/* for the pen */


/* waves */
/* .ocean {
  height: 160px;

  width: 100%;
  position: fixed;

bottom: 15%;
  overflow-x: hidden;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}

.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
} */