/* .infocardContainer * {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-weight: 300;
  }
  .h2 {
    padding: 30px;
    font-weight: 600; font-style: italic; font-family: "Fira Sans Condensed", sans-serif;
  }

  .anchor9 {
    text-decoration: none;
  }
  .anchor9:visited {
    color: #555566;
  }
  .anchor9:hover {
    text-decoration: underline;
  }
  .infocardContainer {
    position: relative;
    display: flex;

    
    width: 100%;
    border-radius: 100px 10px 100px 100px;

    background-image: -webkit-linear-gradient(3600deg, rgba(2, 0, 36, 1) 0%, rgba(0, 52, 120, 1) 50%, rgb(3, 188, 224) 100%);

    transition: all 500ms ease-in;
    transition-delay: 1s;
    margin: auto;
    margin-top: 40px;
    --margin-top: 100px;
    
  }

  
  .infocardContainer div {
    color: white;
    flex-shrink: 1;
    width: 100%;
    --background-color: green;
  }
  .infocardContainer div * {

    text-overflow: hidden;
    --background-color: yellow;
    color: white;
    padding-right: 10px;

    transition: all 450ms ease-in;
    transition-delay: 1s;
  }
  
  
  .infocardContainer #main, .infocardContainer #main img{
    --background-color: red;
    height: 230px;
    width: 230px;
    padding-right: 10px;
    border-radius: 100%;
    flex-shrink: 0;
    object-fit: cover;
  }
  .infocardContainer #main img{
    position: absolute;
    height: 230px;
    width: 230px;
    transition: none;
    display: float;
    left: -15px;
    position: relative;
    border: 10px solid white;
    margin: 0 0 0 0; padding: 0 0 0 0;
  }



 
 */



 .Meritlistbg {
  background-image: url("../Photos/highachieverbg.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 59%;

  position: relative;
  overflow: hidden;

}

.filter12 {
  /* position: absolute;
  padding-top: 140px;
  padding-bottom: 500px;
  background-color: rgb(0, 0, 0, 0.7); */
  position: absolute;
  padding-top: 50%;
  padding-bottom: 50%;
  background-color: rgb(0, 0, 0, 0.4);
  top:50%;
  transform: translate(0%, -50%);
}

.filter12 h1 {
  font-size: 60px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 3px;
}







.three {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.three > li {
  background: #FFFFFF;
  border: 1px solid rgba(150,150,150,0.29);
  -moz-box-shadow: 0px 0px 18px 0px rgba(103,85,85,0.39);
  box-shadow: 0px 0px 18px 0px rgba(103,85,85,0.39);
  width: 290px;
  height: 430px;
  display: inline-block;
  margin: 20px 20px;
  text-align: center;
}
.three > li img {
  margin-top: 60px;
}
.three > li:hover img {
  margin-top: 10px;
}
.three > li:hover {
  -moz-box-shadow: 0px 0px 5px 0px rgba(103,85,85,0.25);
  box-shadow: 0px 0px 5px 0px rgba(103,85,85,0.25);
}
.three > li:hover span {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  bottom: -400px;
}
.three > li:hover h3 {
  color: rgba(255,255,255,1);
  margin-top: 25px;
}
.three > li .wrapper {
  overflow: hidden;
  position: absolute;
  width: 290px;
  height: 430px;
}
.three > li span {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  height: 500px;
  width: 500px;
  background: #003478;
  display: block;
  position: absolute;
  bottom: 150px;
  left: -125px;
}
.three > li span img {
  margin-top: 10px;
  width: 50px;
}
.three > li span:hover {
  background: #003478;
}
.three > li span i {
  position: absolute;
  bottom: -90px;
  color: #003478;
  text-transform: uppercase;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border: 2px solid #003478;
  padding: 10px 30px;
  font-style: normal;
  display: inline-block;
  left: 195px;
}
.three > li span .mamber-img {
  width: 120px;
  margin-top: 150px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}
.three > li span .mamber-img + h3 {
  margin-top: 20px;
}
.three > li h3 {
  margin-top: 40px;
  color: rgba(255,255,255,1);
}
.three > li h3 em {
  display: block;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
  margin-top: 5px;
}
.three .social {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 10px;
}
.three .social li {
  opacity: 0;
  display: inline-block;
  padding: 5px;
}
.three > li:hover .social li {
  opacity: 0.8;
}
.three .social li a {
  cursor: pointer;
  display: block;
}
.three .social li a:hover {
  opacity: 0.5;
}
.three .social li img {
  width: 24px;
}

.transition {
  transition: .3s cubic-bezier(.3, 0, 0, 1.3)
}
.three .transition {
  transition: .7s cubic-bezier(.3, 0, 0, 1.3)
}