.FeeStructurebg{

    background-image: url("..\/Photos\/FeeStructure.jpg");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 90%;
  
    position: relative;
    overflow: hidden;
  
  }
  
  .filter15 {

    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
  }
  
  .filter15 h1 {
    font-size: 60px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 3px;
  }
  