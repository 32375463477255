.btnunique {
    text-decoration: none;
    font-weight: bold;

    grid-column: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 0.500em 0.8em 0.4em;
    box-shadow: 0 0.05em 0.5em rgba(60, 5, 224, 0.904), 0 0.15em 0.3em -0.1em rgba(28, 41, 218, 0.692), inset 0 0 0.05em 0.0333em rgba(67, 70, 231, 0.25), inset 0 0.1em 0.2em 0em rgba(22, 7, 235, 0.25), inset 0 0.1em 0.45em rgba(56, 43, 240, 0.25);
    background: linear-gradient(var(--angle), #0310bd 25%, rgba(67, 78, 231, 0.9) 40%, rgba(67, 78, 231, 0.95) 65%, #003478 85%) no-repeat -1px 50%/300% calc(100% + 2px);
    color: white;
    transition: color 0.3s, background 0.3s, transform 0.75s, background-position 0.5s, box-shadow 0.5s;
    transition: all ease 2s;
    will-change: background-position, transform, box-shadow;
    position: relative;
    border-radius: var(--border-r);
    border: var(--border-w) solid #003478;
}

.btnunique:before,
.btnunique:after {
    content: "";
    border-radius: inherit;
    position: absolute;
    transition: opacity 0.5s, filter 0.5s;
}

.btnunique:before {
    inset: calc(var(--border-w) * -1);
    box-shadow: inset 0.0333em 0.0333em 0.2em #2a1de9, inset -0.0666em -0.0666em 0.15em #0e56b4, inset 0.025em 0.025em 0.1em rgb(255, 255, 255);
    mix-blend-mode: hard-light;
}

.btnunique:after {
    inset: 10;

    border-radius: calc(var(--border-r) - var(--border-w));
    box-shadow: -0.05em -0.05em 0.05em #0e56b4, 0.05em 0.05em 0.05em #2a1de9;
    mix-blend-mode: hard-light;
}

.btnunique:after {
    content: none;
}

.btnunique:hover,
.btnunique:focus {
    background-position: calc(100% + 5px);
    color: white;
    box-shadow: 0 0.15em 1.5em rgba(15, 0, 230, 0.666), 0 0.15em 0.333em -0.1em rgba(94, 155, 247, 0.666);
}

:root {
    --border-w: 0.10rem;
    --border-r: 1em;
    --angle: 130deg;
}





































.button {
    width: fit-content;

    --text: #fff;
    --font-size: 36px;
    --duration: .44s;
    --move-hover: -4px;

    --font-shadow: var(--font-size);
    padding: 25px 40px;
    padding-right: 30px;
    font-family: "Roboto";
    font-weight: 500;

    line-height: 39px;
    border-radius: 24px;
    display: block;
    outline: none;
    text-decoration: none;
    font-size: var(--font-size);
    letter-spacing: 5px;

    color: var(--text);
    box-shadow: var(--shadow);
    transform: translateY(var(--y)) translateZ(0);
    transition: transform var(--duration) ease, box-shadow var(--duration) ease;
}

.button div {
    display: flex;
    overflow: hidden;
    text-shadow: 0 var(--font-shadow) 0 var(--text);
}

.button div span {
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-style: normal;
    transition: transform var(--duration) ease;
    transform: translateY(var(--m)) translateZ(0);
}

.button div span:nth-child(1) {
    transition-delay: 0.05s;
}

.button div span:nth-child(2) {
    transition-delay: 0.1s;
}

.button div span:nth-child(3) {
    transition-delay: 0.15s;
}

.button div span:nth-child(4) {
    transition-delay: 0.2s;
}

.button div span:nth-child(5) {
    transition-delay: 0.25s;
}

.button div span:nth-child(6) {
    transition-delay: 0.3s;
}

.button div span:nth-child(7) {
    transition-delay: 0.35s;
}

.button div span:nth-child(8) {
    transition-delay: 0.4s;
}

.button div span:nth-child(9) {
    transition-delay: 0.45s;
}

.button div span:nth-child(10) {
    transition-delay: 0.5s;
}

.button div span:nth-child(11) {
    transition-delay: 0.55s;
}

.button div span:nth-child(12) {
    transition-delay: 0.6s;
}

.button div span:nth-child(13) {
    transition-delay: 0.65s;
}

.button div span:nth-child(14) {
    transition-delay: 0.7s;
}

.button div span:nth-child(15) {
    transition-delay: 0.75s;
}

.button div span:nth-child(16) {
    transition-delay: 0.8s;
}

.button:hover {
    --y: var(--move-hover);
    --shadow: var(--shadow-hover);
}

.button:hover span {
    --m: calc(var(--font-size) * -1);
}


.text-with-spacing {
    letter-spacing: 4px;
    font-weight: bolder;

}



.button .btnhidden {

    opacity: 0;

    transition: all linear .5s;
}

.button:hover .btnhidden {
    display: inline-block;
    opacity: 1;

}

.fact {
    padding-top: 5rem;
    padding-bottom: 4rem;
background: url("..\/Photos\/bgFaculty.jpg");    
background-repeat: no-repeat;

background-position-y: -100px;



}




.applyonlinebg {
    background-image: url("..\/Photos\/bgApplyonline.jpg");
    width: 100%;
    height: 55vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 60%;
    position: relative;
    overflow: hidden;

}

.filterapplyonline {


    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}



@media (max-width: 580px) {

	.button {
        --font-size: 35px;
        padding: 15px 27px;
        line-height: 35px;
    }

  }


  @media (max-width: 480px) {

	.button {
        --font-size: 25px;
        padding: 15px 20px;
        line-height: 30px;
    }

  }
