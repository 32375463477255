.JuniorHarleybg{
    background-image: url("..\/Photos\/HarleyCampus.png");
    width: 100%;
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:60%;
    position: relative;
    overflow: hidden;
    
}

.PWDbg{
  background-image: url("..\/Photos\/PWDcampus.JPG");
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y:60%;
  position: relative;
  overflow: hidden;
  
}

.JuniorWestridgebg{
  background-image: url("..\/Photos\/JuniorWestridge.png");
  width: 100%;
  height: 77vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y:0%;
  position: relative;
  overflow: hidden;
  
}

.SeniorWestridgebg{
  background-image: url("..\/Photos\/SeniorWestridgeCampus.png");
  width: 100%;
  height: 77vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y:60%;
  position: relative;
  overflow: hidden;
  
}

.GolraMorbg{
  background-image: url("..\/Photos\/GolraMor.png");
  width: 100%;
  height: 77vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y:60%;
  position: relative;
  overflow: hidden;
  
}

.skanspeshawarroadbg{
  background-image: url("..\/Photos\/skanspeshawarroad.png");
  width: 100%;
  height: 77vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y:60%;
  position: relative;
  overflow: hidden;
  
}

.filter5{
    /* position: absolute;

    padding-top: 200px;
    padding-bottom: 500px;
    background-color: rgb(0,0,0,0.7); */
    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}




  
  .th1 {
    color: white;
    background-color: #003478;
  }



  
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.otro-blockquote{
  font-size: 1.2em;
  width:100%;
  /* margin:50px auto; */
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #003478 ;
  line-height:1.6;
  position: relative;
  background:#EDEDED;
}

.otro-blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#003478;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.otro-blockquote::after{
  content: '';
}

.otro-blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}