.bg-blue {
    background-color: #015fc9;
}

.textblue {
    color: #015fc9;
}

.textdarkblue {
    color: #2c2f74;
}

.bg-black-opacity {
    background-color: black;
    opacity: calc(.25);
    width: 100%;
}

.bg-lightgrey {
    background-color: #f2f5f9;
}

.fontcolor {
    color: #696e77;
}

.fontcolordark {
    color: #003a87;
}

.borderblue {

    border-style: solid;
    border-color: #003a87;
}


.bgdarkblue {
    background-color: #003478;
   
}
.bglightblue{
    background-color: #015fc9;
}

.iconlightblue {
    background-color: #0ce0ff;
}

.iconcolor {
    color: #0ce0ff;
}


.dropdown-item:hover {
    /* background-color: #015fc9; */
    color: white;
    border-radius: 5%;
}