

.buttonvirtual {
  cursor: pointer;
  outline: none;
}

.buttonvirtual.outline {
  position: relative;
  z-index: 3;
  background: transparent;
  color: white;
  font-size: 14px;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  border-radius: 22px;
  padding: 10px 40px;
  text-transform: uppercase;
  transition: all 0.2s linear;
}

.buttonvirtual.outline:hover && :focus {
  color: white;
  background: white;
  border-color: white;
  
  transition: all 0.2s linear;
}

.buttonvirtual.outline:active {
  border-radius: 22px;
}

.buttonvirtual.white-blue {
  font-weight: 700;
  color: #00aeef;
  border-color: white;
  background: white;
}

.buttonvirtual.white-blue:hover {
  color: white;
  background: #00aeef;
  border-color: white;
}

.c-listitem__num {
  /* position: absolute;
  z-index: 10; */
  /* top: 40px;
  left: 40px; */
  width: 40px;
  height: 40px;
  display: block;
  line-height: 35px;
  text-align: center;
  border-radius: 20px;
  background-image: linear-gradient(to top, #220170, #0d549a);
  color: white;
  font-weight: 600;
  font-size: 20px;
  
}