.CACalenderbg{
    background-image: url("..\/Photos\/CACalenderbg.jpg");
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:43%;

    position: relative;
    overflow: hidden;
    
}

.filter9{

    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}




.table2 {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
 
    border-radius: 5px;
  }
  
  
  .th2, .td2 {
    text-align: left;
    padding: 16px;
    padding-top:8px;
    padding-bottom:8px;
  
  }
  @media (max-width: 500px) {

    .th2, .td2{
      text-align: left;
      padding: 12px;
      padding-top:4px;
      padding-bottom:4px;
      font-size: small;
  }

    }

    @media (max-width: 400px) {
      .td2{
        padding: 4px;
      }
      .th2{
        text-align: left;
        padding: 4px;
        padding-top:4px;
        padding-bottom:4px;
        font-size: x-small;
    }
  
      }
  
  
  .tr2:nth-child(1) {
    background-color: #003478;
    color:white;

  }
  
  .tr2:nth-child(even) {
       background-image: -webkit-linear-gradient(90deg, #e9ebee 0%, white 100%);
    
  }