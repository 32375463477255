#mySidenav a {
    position: absolute;
    left: -170px;
    transition: 0.3s;
    width: 240px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 0 5px 5px 0;
  }
  
  #mySidenav a:hover {
    left: 0;
  }

  @media (max-width: 700px) {
    #mySidenav a {

      position: absolute;
      left: -170px;
      transition: 0.3s;
      width: 230px;
      text-decoration: none;
      font-size: 20px;
      color: white;
      border-radius: 0 5px 5px 0;

    }

    }
    @media (max-width: 450px) {
      #mySidenav a {
  
        position: absolute;
        left: -170px;
        transition: 0.3s;
        width: 225px;
        text-decoration: none;
        font-size: 20px;
        color: white;
        border-radius: 0 5px 5px 0;
  
      }
  
      }
  
  #about {
    top: 50px;
    background-color: #04AA6D;
  }
  
  #blog {
    top: 130px;
    background-color: #2196F3;
  }
  
  #projects {
    top: 210px;
    background-color: #f44336;
  }
  
  #contact {
    top: 290px;
    background-color: #555
  }



