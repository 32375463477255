.bgFaculty{
    background-image: url("..\/Photos\/bgFaculty.jpg");
    background-size: cover;
    
}
.Facultybluebg{
    background-image: url("..\/Photos\/Facultybg.jpg");
    background-repeat: none;
    background-size: cover;
}
.Facultybg2{
    background-image: url("..\/Photos\/Facultybg2.jpg");
    background-repeat: none;
    background-size: cover;
}


.Facultybg{
    background-image: url("../Photos\/Facultymember.jpg");
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    position: relative;
    overflow: hidden;

}

.filter13 {
    position: absolute;
    padding-top: 500px;
    padding-bottom: 500px;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);

}

.filter13 h1 , .filter13 h2, .filter13 h3{
    font-size: 60px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 3px;
}



  