:root {
 
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 400;
}




.blink-class {
  animation: blink 1.5s linear infinite;
  }
  @keyframes blink {
  25% {
  opacity: 0.5;
  }
  50% {
  opacity: 0;
  }
  75% {
  opacity: 0.5;
  }
  }