.contactbg{
    background-image: url("https://t3.ftcdn.net/jpg/05/30/96/04/240_F_530960431_c8fPd3HansYvrSJ4fJxZqp9OhjQmYoll.jpg");
    width: 100%;
    height: 62vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:60%;
    position: relative;
    overflow: hidden;
    
}

.filter6{

    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}


.VirtualCollegebg{
    background-image: url("https://skans.pk/static/media/home_2.9a4a1d5c7411958dfc6a.webp");
    width: 100%;
    height: 62vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:50%;
    position: relative;
    overflow: hidden;
    
}

.VirtualSchoolbg{
    background-image: url("https://skans.pk/static/media/home_1.f07282df8f5b7b59b1c5.webp");
    width: 100%;
    height: 62vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:50%;
    position: relative;
    overflow: hidden;
    
}
  
 