.blink {
    animation: blink 1s  infinite;
  }

  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }



.black{
    color: black;
}
.black:hover{
    color: white;
}
  .quicklink{

    padding: 10px;
    transition: all .3s ease;
    opacity:90%;

    background-image: -webkit-linear-gradient(90deg, #f0f3f7 0%, white 100%);

  }

  .quicklink:hover{
    color: white;
    font-weight: 700;
    font-size:medium;
    background-color: #003a87;
  

  }
  .quicklink:active{

    background-color: #0d2344;


  }
.nav-tabs .nav-item .nav-link.active {
    background-color: #0d2344 !important;
    color: white;

    border-radius: 6px;
}

.nav-tabs .nav-item .nav-link.active:hover {
  
  color: white !important;
}

.nav-tabs .nav-item .nav-link {
    background-color: transparent !important;
    color: black;
}
.nav-tabs .nav-item .nav-link:hover{
    color: #003a87;
    cursor:pointer;
}