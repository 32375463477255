.programbg{
    /* background-image: url("..\/Photos\/CAprogram1.JPG"); */
    background-image: url("..\/Photos\/CA_prog.png");

    
    width: 100%;
    height: 55vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
    overflow: hidden;
    
}
.ACCAprogrambg{
    /* background-image: url("..\/Photos\/CAprogram1.JPG"); */
    background-image: url("..\/Photos\/acca_main.jpg");

    
    width: 100%;
    height: 55vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
    overflow: hidden;
    
}
.programbgSchool{
    background-image: url("..\/Photos\/image3.jpg");
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    background-color: rgb(0, 0, 0, 0.5);;

    
}
.filter{
    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);

}

.filter2{
    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}



.b {
    background-color: currentColor;
    display: block;
    flex: 1;
    height: 2px;
    opacity: .5;
}