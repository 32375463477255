





a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 6px 10px;
position: fixed;
top:0;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(236, 47, 47, 0.1);
    z-index: 1;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
/* 
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
} */



#sidebar {
    width: 100%;
    /* min-width: 250px;
    max-width: 250px; */
    /* max-height: 100vh; */
    background: #003478;
    color: #fff;
    transition: all 0.3s;
    position: sticky;
    
 
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 15px;
    background: #003478;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #094664;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    /* font-size: 1.1em; */
    display: block;
    text-decoration: none;
    color: #fff;

}

#sidebar ul li a:hover {
    color: #003478;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #2b4de2;
}

a[data-toggle="collapse"] {
    position: relative;
    transition: all .4s ease-in-out;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #273ea8;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;

    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    /* #sidebar {
        margin-left: -250px;
    } */
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}



.thover{
    font-weight: 400;
    transition: all .1s linear;
}
.thover i{
    transform: scale(1);
    transition: all .1s linear;
}

.thover:hover{
    font-weight: 800;
}

.thover:hover i{
    transform: scale(1.3);
}


.cardsbg{
    background-image: url("../Photos/cardsbg.jpg");
    background-repeat: none;
    background-position: center;
}