.Gallerybg{

  background-image: url("../Photos/SkansGallery.gif");
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  position: relative;
  overflow: hidden;

}


.filter14 {

  position: absolute;
  padding-top: 70%;
  padding-bottom: 50%;
  background-color: rgb(0, 0, 0, 0.5);
  top:50%;
  transform: translate(0%, -50%);
}

.filter14 h1 {
  font-size: 60px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 3px;
}

.content .content2 {
  opacity: 0;
  font-size: 16px;
  position: absolute;
  display: flex;
  align-items:flex-end;
  justify-content: center;


  color: white;
  padding-top: 10px;
  padding-bottom: 10px;


  /* width: 100%;
  height: 100%; */
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
  /* background-color: rgba(0,0,0,0.1); */

}
.content .content2:hover {
  opacity: 1;

}
.content .content2 .text {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
}
.content .content2:hover .text {
  opacity: 1;
  transform: translateY(250px);
  -webkit-transform: translateY(250px);
}







.full {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.full .content {
  background-color: rgba(0,0,0,0.75) !important;
  height: 100%;
  width: 100%;
  display: grid;
}
.full .content img {
  left: 50%;
top:0;
  transform: translate3d(0, 0, 0);
  animation: zoomin 1s ease;
  max-width: 60%;
  max-height: 60%;
  transform: translate(0%,-30%);
  margin: auto;
  /* margin-top: 13%; */

}
.byebye {
  opacity: 0;
}
.byebye:hover {
  transform: scale(0.2) !important;
}
.gallery {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 8px;
}
.gallery img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 16px #333;
  transition: all 1.5s ease;
}
.gallery img:hover {
  box-shadow: 0 0 32px #333;
}
.gallery .content {
  padding: 4px;
}
.gallery .gallery-item {
  transition: grid-row-start 300ms linear;
  transition: transform 300ms ease;
  transition: all 0.5s ease;
  cursor: pointer;
}
.gallery .gallery-item:hover {
  transform: scale(1.025);
}
@media (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    
  }

  .full .content img {
    left: 50%;
  top:0;
    transform: translate3d(0, 0, 0);
    animation: zoomin 1s ease;
    max-width: 80%;
    max-height: 80%;
    /* transform: translate(0%,50%); */
    margin: auto;
    /* margin-top: 13%; */
  
  }
}
@media (max-width: 400px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  .full .content img {
    left: 50%;
  top:0;
    transform: translate3d(0, 0, 0);
    animation: zoomin 1s ease;
    max-width: 92%;
    max-height: 92%;
    /* transform: translate(0%,50%); */
    margin: auto;
    /* margin-top: 13%; */
  
  }
}
@-moz-keyframes zoomin {
  0% {
    max-width: 10%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
 
    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 35%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 55%;
    transform: rotate(0deg);
  }
}
@-webkit-keyframes zoomin {
  0% {
    max-width: 10%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
 

    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 35%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 55%;
    transform: rotate(0deg);
  }
}
@-o-keyframes zoomin {
  0% {
    max-width: 10%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
 

    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 35%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 55%;
    transform: rotate(0deg);
  }
}
@keyframes zoomin {
  0% {
    max-width: 10%;
    transform: rotate(-30deg);
    filter: blur(4px);
  }
  30% {
   

    filter: blur(4px);
    transform: rotate(-80deg);
  }
  70% {
    max-width: 35%;
    transform: rotate(45deg);
  }
  100% {
    max-width: 55%;
    transform: rotate(0deg);
  }
}
