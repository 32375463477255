.imgdesign {
    width: 500px;
    height: 200px;
}
.CANoticeboardbg {
    background-image: url("..\/Photos\/CANoticboard.jpg");
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 85%;

    position: relative;
    overflow: hidden;

}

.filter10 {

    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}

.filter10 h1 {
    font-size: 60px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 3px;
}



.event_date {
    width: 82px;
    background: #003478;
    float: left;
    text-align: center;
    border-radius: 2px;
}

.event-date-wrap {

    border: 1px dashed white;
    margin: 8px;
    padding: 4px 0;
}

.event-date-wrap p {
    font-size: 23px;
    font-weight: 700;
    color: #fff;
    margin: 0;
}


.noticecat {
    background-image: -webkit-linear-gradient(90deg, #e9ebee 0%, white 100%);
    color: #003478;
}

.noticecat:hover {
    background-image: -webkit-linear-gradient(3600deg, rgba(2, 0, 36, 1) 0%, rgba(0, 52, 120, 1) 50%, rgb(3, 188, 224) 100%);
    color: white;
}

.noticeAtive{
    background-image: -webkit-linear-gradient(3600deg, rgba(2, 0, 36, 1) 0%, rgba(0, 52, 120, 1) 50%, rgb(3, 188, 224) 100%);
    color: white;
}
