.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;


   
  }
 
  .popup-box1 {
    position: fixed;
    background: #003478d8;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
  }

  .popup-box2 {
    position: fixed;
    background: #003478d8;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
  }
   
  .box {
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: proximity;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 90vh;
    /* margin-top: calc(10vh - 10vh - 10px); */
    margin-top: 10%;
    background: #fff;
    border-radius: 10px;
    /* padding: 30px; */
    border: 1px solid #999;
    transition: all 1s ease-in-out;
    /* opacity: 0; */
    overflow: auto;
    transition-delay: 5s;
  }


  .box2 {
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: proximity;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    /* margin-top: calc(10vh - 10vh - 10px); */
    margin-top: 5%;

    background: #fff;
    border-radius: 10px;
    /* padding: 30px; */
    border: 1px solid #999;
    transition: all 1s ease-in-out;
    /* opacity: 0; */
    overflow: auto;
    transition-delay: 5s;
  }

   
  /* .close-icon {
   
    cursor: pointer;
    position: fixed;
    right: calc(33% - 30px);
    top: calc(81vh - 60vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    color: black;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    z-index: 2;
  } */

  .close-icon {
    content: "X";
    cursor: pointer;
    position: absolute; /* Use absolute positioning relative to the parent */
    top: 15px; /* Adjust the top position as needed */
    right: 10px; /* Adjust the right position as needed */
    width: 25px;
    height: 25px;
    color: black;
  
    line-height: 25px; /* Match the height to center the 'x' */
    text-align: center;

    font-size: 18px; /* Adjust the font size as needed */
    z-index: 2; /* Ensure it's above other content */
  }

  @media screen and (max-width: 1130px) {
    .box {
    
        width: 60%;
    }
  }
  @media screen and (max-width: 860px) {
    .box {
    
        width: 70%;
    }
  }

  @media screen and (max-width: 691px) {
    .box {
    
        width: 80%;
    }
  }

  @media screen and (max-width: 592px) {
    .box {
    
        width: 90%;
    }
  }

  @media screen and (max-width: 518px) {
    .box {
    
        width: 95%;
    }
  }