

.slider {
  margin:0 10px;
  overflow:"hidden";
  padding:2rem 0;
}

.slider img {
  width: 100%;
  border-radius:20px;
}

.react-multi-carousel-list {
    margin: 3rem 0 0 0rem;
    
    }