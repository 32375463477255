.facilitybg{
    background-image: url("..\/Photos\/facility.jpg");
    width: 100%;
    height: 45vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:60%;
    position: relative;
    overflow: hidden;
    
}

.filter3{

    position: absolute;
    padding-top: 50%;
    padding-bottom: 50%;
    background-color: rgb(0, 0, 0, 0.5);
    top:50%;
    transform: translate(0%, -50%);
}