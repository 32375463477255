.homiehover{
    background-color: none;
    color: black;
    transition: all .3s ease;
}


.homiehover:hover{
    background-image: url("..\/Photos\/cardsbg.gif");
    color: white;
}

.homiehover img{
    transition: all .5s ease;
    transform: scale(0.9);
}

.homiehover:hover img{
    transform: scale(1.05);
}

.adminheading{
    font-weight: bold;
    color: black;
  -webkit-text-fill-color: white; 
  -webkit-text-stroke: 2px black;
  font-size:50px;
   
  
}

.adminName{
    background-color: #003478;
    color: White;
    font-weight: bold;
    
}



