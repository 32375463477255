

/* a, a:hover, a.active, a:active, a:visited, a:focus{
	color:#fefefe;
	text-decoration:none;
 
} */
/* .content{
	margin: 0px 100px 0px 100px;
  background: #08005e;
} */

a{
  cursor: pointer;
}

.zindex5{
  z-index: 5;
}
.zindex4{
  z-index: 4;
}

.exo-menu{
	/* width: 100%; */
	/* float: left; */
    /* display: flex; */
	/* list-style: none; */
  /* justify-content: space-around;
  align-items: center; */
	/* position:relative; */
	background: #08005e;
  /* border-radius: 8px; */
  font-size: 12px;
  font-weight: bolder;
  padding-top: 4px;
  padding-bottom:4px;
  /* white-space: nowrap; */

}
/* .exo-menu > li {	 */
    /* display: block;  */
    /* float: center;  */
    /* padding-right: 5px; */
/* } */
.exo-menu > li > a{
	color: #fff;
  /* padding-top: 15px;
  padding-bottom: 15px;
    padding: 15px; */
	text-decoration: none;
	text-transform: uppercase;
	/* border-right: 1px #365670 dotted; */
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
}
.exo-menu > li > a.active,
.exo-menu > li > a:hover{
	background:#009FE1;
	color:#fff;
    /* padding: 20px; */

}


li.drop-down ul > li > a:hover{
	background:#01567a;
	color:#fff;
    /* padding: 20px; */

}
.exo-menu > li:hover ,.exo-menu >li:active{	
  /* display: block;  */
  /* float: center;  */
	background:#009FE1;

}
/* .exo-menu i {
  float: left;

  margin-right: 6px;
  line-height: 20px !important;
} */
li.drop-down,
.flyout-right,
.flyout-left{position:relative;}
li.drop-down:before {
  /* content: "\f103"; */
  content:"";
  color: #fff;
  font-family: FontAwesome;
  font-style: normal;
  /* display: inline; */
  position: absolute;
 
  right: 50%;
  /* right: 50%;  */
  bottom: 7px;
  font-size: 14px;
}

li.drop-down>ul{
  
	left: 0px;
	min-width: 200px;
    list-style: none;

}
.drop-down-ul{display:none;}
.flyout-right>ul,
.flyout-left>ul{
  top: 0;
  min-width: 200px;
  display: none;
  list-style: none;

  /* border-left: 1px solid #365670; */
  }

li.drop-down>ul>li>a,
.flyout-right ul>li>a ,
.flyout-left ul>li>a {
	color: #fff;
	display: block;
	padding: 15px 18px;
	text-decoration: none;
	/* background-color: #1555a8; */
	background-color: #08005e;

	border-bottom: 1px dotted #547787;
	-webkit-transition: color 0.2s linear, background 0.2s linear;
	-moz-transition: color 0.2s linear, background 0.2s linear;
	-o-transition: color 0.2s linear, background 0.2s linear;
	transition: color 0.2s linear, background 0.2s linear;
}
.flyout-right ul>li>a ,
.flyout-left ul>li>a {
	border-bottom: 1px dotted #B8C7BC;
}


/*Flyout Mega*/
/* .flyout-mega-wrap {
	top: 0;
	right: 0;
	left: 100%;
	width: 100%;
	display:none;
	height: 100%;
	padding: 15px;
	min-width: 742px;

}
h4.row.mega-title {
  color:#eee;
  margin-top: 0px;
  font-size: 14px;
  padding-left: 15px;
  padding-bottom: 13px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
 }
.flyout-mega ul > li > a {
  font-size: 90%;
  line-height: 25px;
  color: #fff;
  font-family: inherit;
}
.flyout-mega ul > li > a:hover,
.flyout-mega ul > li > a:active,
.flyout-mega ul > li > a:focus{
  text-decoration: none;
  background-color: transparent !important;
  color: #ccc !important
} */
/*mega menu*/

/* .mega-menu {
  left: 0;
  right: 0;
  padding: 15px;
  display:none;
  padding-top: 0;
  min-height: 100%;

}
h4.row.mega-title {
  color: #eee;
  margin-top: 0px;
  font-size: 14px;
  padding-left: 15px;
  padding-bottom: 13px;
  text-transform: uppercase;
  border-bottom: 1px solid #547787;
  padding-top: 15px;
  background-color: #365670
  }
 .mega-menu ul li a {
  line-height: 25px;
  font-size: 90%;
  display: block;
}
ul.stander li a {
    padding: 3px 0px;
}

ul.description li {
    padding-bottom: 12px;
    line-height: 8px;
}

ul.description li span {
    color: #ccc;
    font-size: 85%;
}
a.view-more{
  border-radius: 1px;
  margin-top:15px;
  background-color: #009FE1;
  padding: 2px 10px !important;
  line-height: 21px !important;
  display: inline-block !important;
}
a.view-more:hover{
	color:#fff;
	background:#0DADEF;
}
ul.icon-des li a i {
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    background-color: #009FE1;
    line-height: 35px !important;
}

ul.icon-des li {
    width: 100%;
    display: table;
    margin-bottom: 11px;
} */
/*Blog DropDown*/
/* .Blog{
	left:0;
	display:none;
	color:#fefefe;
	padding-top:15px;
	background:#547787;
	padding-bottom:15px;
}
.Blog .blog-title{
	color:#fff;
	font-size:15px;
	text-transform:uppercase;

}
.Blog .blog-des{
	color:#ccc;
	font-size:90%;
	margin-top:15px;
}
.Blog a.view-more{
	margin-top:0px;
} */
/*Images*/
/* .Images{
	left:0;
   width:100%;
	 display:none;
	color:#fefefe;
	padding-top:15px;
	background:#547787;
	padding-bottom:15px;
}
.Images h4 {
  font-size: 15px;
  margin-top: 0px;
  text-transform: uppercase;
} */
/*common*/
.flyout-right ul>li>a ,
.flyout-left ul>li>a,
.flyout-mega-wrap,
.mega-menu{
	background-color: #1d72e2;
}

/*hover*/
.Blog:hover,
.Images:hover,
.mega-menu:hover,
.drop-down-ul:hover,
li.flyout-left>ul:hover,
li.flyout-right>ul:hover,
.flyout-mega-wrap:hover,
li.flyout-left a:hover +ul,
li.flyout-right a:hover +ul,
.blog-drop-down >a:hover+.Blog,
li.drop-down>a:hover +.drop-down-ul,
.images-drop-down>a:hover +.Images,
.mega-drop-down a:hover+.mega-menu,
li.flyout-mega>a:hover +.flyout-mega-wrap{
	display:block;
    

}
/*responsive*/

@media (max-width:996px){
	.exo-menu > li > a{
	padding: 14px;

 }
}
 @media (min-width:992px){
	.exo-menu > li > a{
	display:block;

 }
 .exo-menu {
  /* min-height: 45px; */
  background-color: #08005e;
  width: 100%;
}


.mega-menu, .flyout-mega-wrap, .Images, .Blog,.flyout-right>ul,
.flyout-left>ul, li.drop-down>ul{
		position:absolute;
}
 .flyout-right>ul{
	left: 100%;
	}
	 .flyout-left>ul{
	right: 100%;
}
 }
@media (max-width:992px){
  li.drop-down:before {
    /* content: "\f103"; */
    content:"\f078";
    color: #fff;
    font-family: FontAwesome;
    font-style: normal;
    /* display: inline; */
    position: absolute;
   
    right: 0%;
    /* right: 50%;  */
    /* bottom: 12px; */
    font-size: 14px;
  }

    .exo-menu {
        /* min-height: 45px; */
        background-color: #08005e;
        width: 100%;
    }
	
	.exo-menu > li > a{
		width:100% ;
	    /* display:none ; */
        display: contents;
  
	
	}
	.exo-menu > li{
		width:100%;      
        padding: 14px;
	}
	.display.exo-menu > li > a{
	  display:block ;
	  	padding: 5px 22px;
	}
	
.mega-menu, .Images, .Blog,.flyout-right>ul,
.flyout-left>ul, li.drop-down>ul{
		position:relative;
        list-style: none;
}

}

a.toggle-menu{
    position: absolute;
    right: 0px;
    padding-right: 20px;
    font-size: 27px;
    color: #08005e;
    top: 4px;
}



.offcanvas-body{
    overflow-x: hidden;
}

.positionabsolute{
  /* white-space: nowrap; */
  width: 100%;
  position: absolute;
  left: 50%;
  top: 100%;
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);

}


.positionabsolute1{
  white-space: nowrap;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 100%;
  /* -ms-transform: translate(-50%, -40%); */
  /* transform: translate(-50%, -40%); */

}