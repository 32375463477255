/*=============== GOOGLE FONTS ===============*/
/* @import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600&display=swap"); */

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(38, 92%, 58%);
  --first-color-light: hsl(38, 100%, 78%);
  --first-color-alt: hsl(32, 75%, 50%);
  --second-color: hsl(195, 75%, 52%);
  --dark-color: hsl(212, 40%, 12%);
  /* --white-color: hsl(212, 4%, 95%); */
  /* --body-color: hsl(212, 97%, 13%); */
  --container-color: hsl(0, 0%, 100%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Bai Jamjuree", sans-serif;
  --h2-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

/*=============== BASE ===============*/


a {
  text-decoration: none;
}

/* img {
  display: block;
  max-width: 100%;
  height: auto;
} */

/*=============== CARD ===============*/


/* .card__container {
  padding-block: 5rem;
} */

.card__content {
  margin-inline: 1.75rem;
  /* border-radius: 1.25rem; */
  overflow: hidden;
}

.card__article {
  width: 300px; /* Remove after adding swiper js */
  border-radius: 1.25rem;
  overflow: hidden;
}

/* .card__image { */
  /* position: relative; */
  /* background-color: var(--first-color-light); */
  /* padding-top: 1.5rem; */
  /* margin-bottom: -.75rem; */
/* } */

.card__data {
  background-color: var(--container-color);
  padding: 1.5rem 2rem;
  /* border-radius: 1rem; */
  text-align: center;
  /* position: relative; */
  z-index: 10;
}

.card__img {
    width: 250px;
  height: auto;
  /* margin: 0 auto; */
  object-fit: cover;
  /* object-position: center; */
  /* position: relative; */
  /* z-index: 5; */
}

.card__shadow {
  width: 200px;
  height: 200px;
  background-color: var(--first-color-alt);
  border-radius: 50%;
  position: absolute;
  top: 3.75rem;
  left: 0;
  right: 0;
  margin-inline: auto;
  filter: blur(45px);
}

.card__name {
  font-size: var(--h2-font-size);
  color: var(--second-color);
  margin-bottom: .75rem;
}

.card__description {
  font-weight: 500;
  margin-bottom: 1.75rem;
}

.card__button {
  display: inline-block;
  background-color: var(--first-color);
  padding: .75rem 1.5rem;
  border-radius: .25rem;
  color: var(--dark-color);
  font-weight: 600;
}

/* Swiper class */
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next {
  width: initial;
  height: initial;
  font-size: 3rem;
  color: var(--second-color);
  display: none;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-pagination-bullet {
  background-color: hsl(212, 32%, 40%);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--second-color);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .card__data {
    padding: 1rem;
  }
}

/* For medium devices */
@media screen and (min-width: 768px) {
  .card__content {
    margin-inline: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: block;
  }
}

/* For large devices */
@media screen and (min-width: 1120px) {
  .card__container {
    max-width: 1120px;
  }

  .swiper-button-prev {
    left: -1rem;
  }
  .swiper-button-next {
    right: -1rem;
  }
}